// ==================================================
//  Youtube iframe API
//  https://developers.google.com/youtube/iframe_api_reference?hl=ja#Playback_quality
//  playerVars: https://developers.google.com/youtube/player_parameters.html?playerVersion=HTML5&hl=ja
// ==================================================
const tag = document.createElement("script");
tag.src = "https://www.youtube.com/iframe_api";
const firstScriptTag = document.getElementsByTagName("script")[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
let ytPlayer;
function onYouTubeIframeAPIReady() {
  ytPlayer = new YT.Player("youtube", {
    // id="youtube"
    videoId: "1UD6YUVXwYY", // 動画のアドレスの指定
    playerVars: {
      playsinline: 1, // インライン再生を行う
      autoplay: 1, // 自動再生を行う
      fs: 0, // 全画面表示ボタンを表示しない
      rel: 0, // 再生中の動画と同じチャンネルの関連動画を表示
      controls: 0, // プレーヤー コントロールを表示しない
      modestbranding: 1, // YouTubeロゴの非表示
      iv_load_policy: 3, // アノテーションの非表示
      start: 0, // 50秒後から動画がスタート
      disablekb: 1, // キーボード操作を無効
    },
    events: {
      onReady: onPlayerReady,
      onStateChange: onPlayerStateChange,
    },
  });
}
// ミュートにしてから再生する設定
function onPlayerReady(event) {
  event.target.mute();
  event.target.playVideo();
}
// ループ設定
function onPlayerStateChange(event) {
  if (event.data == YT.PlayerState.ENDED) {
    event.target.playVideo();
  }
  if (event.data == YT.PlayerState.PAUSED) {
    // 一時停止防止
    event.target.playVideo();
  }
}
// 操作防止用要素追加
const youtubeElement = document.getElementById("youtube");
let newElement = document.createElement("div");
newElement.classList.add("youtube-mask");
const addNewElement = youtubeElement.before(newElement);
